import { http_get, http_post } from ".";
// 获取商户首页信息
export const getMerIndexApi = () => http_get("/api/mer/info");
// 获取商户商品列表
export const getMerGoodsApi = (data) => http_get("api/mer/goods", data);
//获取商户商品详情数据
export const getMerGoodApi = (id) => http_get(`/api/mer/goods/${id}`);
// 商户修改商品配置
export const editMerGoodApi = (id, data) => http_post(`/api/mer/goods/${id}`, data);
//获取订单列表 api
export const getMerOrderListApi = ({ page = 1, limit = 10, good_id = 0, mobile = 0, status = 2, }) => http_get("/api/mer/trades", {
    page,
    limit,
    good_id,
    mobile,
    status,
});
//核销订单
export const merVerifyOrder = (id) => http_post("/api/mer/verify", { trade_id: id });
