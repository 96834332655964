import { showToast as vantShowToast, showSuccessToast, showFailToast, showLoadingToast } from "vant";
import router from "@/router";
export function cache(key, value = "") {
    if (value == "") {
        return window.localStorage.getItem(key);
    }
    else if (value == null) {
        window.localStorage.removeItem(key);
    }
    else {
        window.localStorage.setItem(key, value);
    }
}
export function toPage(path, query = {}) {
    if (path == "")
        return showToast("暂未开通，敬请期待");
    router.push({
        path: path,
        query: query,
    });
}
export function showLoading(msg) {
    return showLoadingToast({
        message: msg,
        forbidClick: true,
    });
}
export function showToast(msg, icon = "") {
    switch (icon) {
        case "success":
            return showSuccessToast(msg);
        case "fail":
            return showFailToast(msg);
        default:
            return vantShowToast(msg);
    }
}
export function __ArrSort(arr, id = 0) {
    var temp = [], lev = 0;
    var forFn = function (arr, id, lev, parent_nickname = "") {
        for (var i = 0; i < arr.length; i++) {
            var item = arr[i];
            if (item.parent_id == id) {
                item.lev = lev;
                item.to_nickname = parent_nickname;
                temp.push(item);
                forFn(arr, item.id, lev + 1, item.nickname);
            }
        }
    };
    forFn(arr, id, lev);
    return temp;
}
//获取32位随机字符串
export function randomString(len = 32) {
    var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"; //排除了一些混淆随机字符
    var maxPos = chars.length;
    var rand = "";
    for (var i = 0; i < len; i++) {
        rand += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return rand;
}
//数组转字符串分割
export function arr2string(arr) {
    var str = "";
    for (var i = 0; i < arr.length; i++) {
        str += "," + arr[i];
    }
    return str.slice(1);
}
//图片数组转为字符串格式
export function imgList2string(imgList) {
    const item = imgList[0];
    console.log(item);
    var arr = [];
    for (var i = 0; i < imgList.length; i++) {
        arr.push(imgList[i].path);
    }
    return arr2string(arr);
}
//时间戳人性化转化
export function timeFormat(timestamp) {
    var timestamp = timestamp - 3;
    // 补全为13位
    var arrTimestamp = (timestamp + "").split("");
    for (var start = 0; start < 13; start++) {
        if (!arrTimestamp[start])
            arrTimestamp[start] = "0";
    }
    timestamp = Number(arrTimestamp.join("")) * 1;
    var minute = 1000 * 60;
    var hour = minute * 60;
    var day = hour * 24;
    var halfamonth = day * 15;
    var month = day * 30;
    var now = new Date().getTime();
    var diffValue = now - timestamp;
    // 如果本地时间反而小于变量时间
    if (diffValue < 0)
        return "不久前";
    // 计算差异时间的量级
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    // 数值补0方法
    var zero = function (value) {
        if (value < 10) {
            return "0" + value;
        }
        return value;
    };
    // 使用
    if (monthC > 11) {
        // 超过1年，直接显示年月日
        return (function () {
            return "一年前";
        })();
    }
    else if (monthC >= 1) {
        return parseInt(String(monthC)) + "月前";
    }
    else if (weekC >= 1) {
        return parseInt(String(weekC)) + "周前";
    }
    else if (dayC >= 1) {
        return parseInt(String(dayC)) + "天前";
    }
    else if (hourC >= 1) {
        return parseInt(String(hourC)) + "小时前";
    }
    else if (minC >= 1) {
        return parseInt(String(minC)) + "分钟前";
    }
    return "刚刚";
}
export function getNowDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return [year, month, day];
}
