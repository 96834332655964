const routers = [
    {
        path: "/group_admin/groups",
        name: "group_admin_group",
        component: () => import("../views/group_admin/groups.vue"),
    },
    {
        path: "/group_admin/earns",
        name: "group_admin_earnings",
        component: () => import("../views/group_admin/earnings.vue"),
    }
];
export default routers;
