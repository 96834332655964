const state = {
    //请求学校防抖
    is_getting_school: false,
    //学校数据存储
    school: {
        school_id: 0,
        code: 0,
        name: "",
        shortname: "",
        icon_img: "",
        students: 0,
    },
    // 用户所在城市id的存储
    city: {
        id: 0,
        text: ''
    },
    //登录标识
    token: "",
    //登录状态
    loginStatus: false,
    //用户数据存储
    userinfo: {
        id: 0,
        nickname: "",
        avatar: "",
        status: 1,
        auth: 0,
        msg_num: 0,
        is_verify: 0,
        score: 0,
        can_sign: 0,
    },
    //分类列表
    cateList: [],
    //广告
    adList: [],
    //热榜列表
    hotList: [],
    //置顶列表
    topList: [],
    //实时热榜
    realtimeHotList: [],
    //一周热榜
    weekHotList: [],
    //商户信息
    merInfo: {
        id: 0,
        title: '',
        user_id: 0,
        address: '',
        content: '',
        logo: '',
        mobile: '',
        remark_nums: 0,
        score: 0,
        status: 1,
        trade_nums: 0
    }
};
export default state;
