const routers = [
    {
        path: "/d/:tid",
        name: "d",
        meta: { suffix: false },
        component: () => import("../views/post/index.vue"),
    },
    {
        path: "/hot",
        name: "hot",
        component: () => import("../views/post/hot.vue"),
    },
    {
        path: "/post",
        name: "post",
        component: () => import("../views/post/index.vue"),
        beforeEnter: (to, from) => {
            if (!to.query.id) {
                return { path: "/" };
            }
        },
    },
    {
        path: "/mypost",
        name: "mypost",
        component: () => import("../views/my/post.vue"),
    },
    {
        path: "/edit",
        name: "edit",
        component: () => import("../views/post/edit.vue"),
    },
    {
        path: "/verify",
        name: "verify",
        component: () => import("../views/my/verify.vue"),
    },
    {
        path: "/lock",
        name: "lock",
        component: () => import("../views/lock.vue"),
    },
    {
        path: "/myinfo",
        name: "myinfo",
        component: () => import("../views/my/info.vue"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/register.vue"),
    },
    {
        path: "/school",
        name: "school",
        meta: {
            title: "选择学校",
            suffix: false
        },
        component: () => import("../views/school.vue"),
    },
    {
        path: "/block",
        name: "block",
        meta: { suffix: false },
        component: () => import("../views/block.vue"),
    },
    {
        path: "/pages/:code",
        name: "pages",
        meta: { suffix: false },
        component: () => import("../views/pages.vue"),
    },
];
export default routers;
