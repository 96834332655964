// 拜访管理-前端缓存 namespace：命名空间，visitPlanList=拜访计划  visitFeedbackList=拜访反馈
export const setStorage = (key, data, time, namespace = "visitPlanList") => {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {};
    storage[key] = {
        data,
        createTime: Date.now(),
        lifeTime: time || 1000 * 60 * 60 * 24 * 7, // 默认设置过期时间：7天
    };
    localStorage.setItem(namespace, JSON.stringify(storage));
};
export const getStorage = (key, namespace = "visitPlanList") => {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {};
    if (!storage[key])
        return;
    const { data, createTime, lifeTime } = storage[key];
    // 当前时间 - 存入时间 > 过期时间
    if (Date.now() - createTime > lifeTime) {
        delete storage[key];
        localStorage.setItem(namespace, JSON.stringify(storage));
        return null;
    }
    else {
        return data;
    }
};
export const deleteStorage = (namespace = "visitPlanList", key) => {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {};
    if (!storage[key])
        return;
    delete storage[key];
    localStorage.setItem(namespace, JSON.stringify(storage));
};
