const AppDebug = true;
let apiUrl = AppDebug
    ? "https://b.dxxy.cc"
    : "https://b.dxxy.cc";
if (process.env.NODE_ENV == "production") {
    apiUrl = "https://b.dxxy.cc";
}
export const webUrl = apiUrl;
export const ossBaseUrl = "";
export const qiniukodoConfig = {
    ak: 'sNvugcLcUv1Yw8vBc0LrJep1ggzSPW77zYTcixsR',
    sk: 'doToFMyqbZyfZYIYJ8ka4Bj5SGOxq5i0rNtDOsGp',
    bucket: 'dxxy',
    domain: ''
};
