import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "default-passive-events";
//导入自适应
import "amfe-flexible";
//引入vant样式
import "vant/lib/index.css";
//全局注册组件
// import globalComponents from "./components/global";
import "./components/style";
import { Lazyload } from "vant";
import { setupRouter } from "./router";
const app = createApp(App);
app.use(Lazyload).use(store);
setupRouter(app);
app.mount("#app");
