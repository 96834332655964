import { getStorage, setStorage } from "@/common/utils/localStorage";
import { emptyArr } from "@/common/utils/type";
import { getAdApi, getCateListApi, getPostListApi, getSchoolApi, getUserApi, } from "@/http/api";
import merGetters from "@/store/merGetters";
import { getCityApi } from "@/http/pointsApi";
const getters = {
    //获取分类
    cateList: async (state) => {
        if (state.cateList.length == 0) {
            //后台请求分类
            const { data } = await getCateListApi();
            state.cateList = data;
            // const cateList = getStorage("hotList");
            // if (cateList) {
            //     state.cateList = JSON.parse(cateList);
            // } else {
            //     //后台请求分类
            //     const { data } = await getCateListApi();
            //     state.cateList = data;
            //     setStorage('cateList', data, 1000 * 60 * 5)
            // }
        }
        return state.cateList;
    },
    //获取学校信息
    school: async (state) => {
        if (state.school.school_id == 0) {
            // const school = window.localStorage.getItem("school");
            // if (school) {
            //     state.school = JSON.parse(school);
            // } else {
            //     //请求学校
            //     const res = await getSchoolApi();
            //     if (res.success) {
            //         state.school = res.data;
            //         window.localStorage.setItem("school", JSON.stringify(res.data));
            //     }
            // }
            //请求学校
            const res = await getSchoolApi();
            if (res.success) {
                state.school = res.data;
                state.is_getting_school = false;
            }
        }
        return state.school;
    },
    //获取用户信息
    userinfo: async (state) => {
        if (state.userinfo.id == 0) {
            const { data } = await getUserApi();
            if (!emptyArr(data))
                state.userinfo = data;
        }
        return state.userinfo;
    },
    //获取用户权限
    auth: (state, getters) => {
        return state.userinfo.auth || 0;
    },
    //获取用户未读消息
    msgNum: (state) => {
        return state.userinfo.msg_num || 0;
    },
    userId: (state) => {
        return state.userinfo.id || 0;
    },
    adList: async (state) => {
        if (state.adList.length == 0) {
            //获取广告图片
            const { data } = await getAdApi();
            state.adList = data;
        }
        return state.adList;
    },
    score: (state) => {
        return state.userinfo.score || 0;
    },
    //热榜
    hotList: async (state) => {
        if (state.hotList.length == 0) {
            const hotList = getStorage("hotList");
            if (hotList) {
                state.hotList = hotList;
            }
            else {
                //获取热榜
                const { data } = await getPostListApi({
                    is_hot: 1,
                });
                state.hotList = data;
                setStorage("hotList", data, 1000 * 60 * 5);
            }
        }
        return state.hotList;
    },
    //实时热榜
    realtimeHotList: async (state) => {
        if (state.realtimeHotList.length == 0) {
            //实时热榜
            const { data } = await getPostListApi({ is_realtime_hot: 1 });
            state.realtimeHotList = data;
        }
        return state.realtimeHotList;
    },
    //一周热榜
    weekHotList: async (state) => {
        if (state.weekHotList.length == 0) {
            const weekHotList = getStorage("weekHotList");
            if (weekHotList) {
                state.weekHotList = weekHotList;
            }
            else {
                //获取一周热榜
                const { data } = await getPostListApi({
                    is_week_hot: 1,
                });
                state.weekHotList = data;
                setStorage("weekHotList", data, 1000 * 60 * 5);
            }
        }
        return state.weekHotList;
    },
    //置顶文章
    topList: async (state) => {
        if (state.topList.length == 0) {
            //获取置顶文章
            const { data } = await getPostListApi({ is_top: 1 });
            state.topList = data;
        }
        return state.topList;
    },
    // 城市
    city: async (state) => {
        if (state.city.id > 0) {
            return state.city;
        }
        const { data } = await getCityApi();
        if (data.length !== 0) {
            state.city.id = data[0].id;
            state.city.text = data[0].text;
        }
        return state.city;
    },
    // 商户信息处理
    ...merGetters
};
export default getters;
