import { http_get, http_post, http_put } from ".";
export const getScoreGoodList = (data) => http_get("/api/score/goods", data);
//获取积分详情
export const getGoodDetailApi = (gid) => http_get(`/api/score/goods/${gid}`);
//积分下单
export const placeOrderApi = (gid) => http_post("/api/score/trades", { gid: gid });
export const getOrderListApi = (data) => http_get("/api/score/trades", data);
//获取积分订单详情
export const getOrderDetail = (data) => http_get("/api/score/trades/" + data.id, { user_id: data.user_id });
//用户核销订单
export const updateOrder = (id, data) => http_put(`/api/score/trades/${id}`, data);
// 检索用户学校信息
export const getCityApi = (kwd = "") => http_get("/api/search_kwd/city", { kwd: kwd });
//获取商圈信息
export const getCirclesApi = (city_id) => http_get("/api/search_kwd/circle", { city_id: city_id });
