import { getMerIndexApi } from "@/http/merApi";
import { Dialog } from "vant";
import { toPage } from "@/common/utils";
const merGetters = {
    merInfo: async (state) => {
        if (state.merInfo.id == 0) {
            //获取商家信息
            const res = await getMerIndexApi();
            if (res.success) {
                state.merInfo = res.data;
                return state.merInfo;
            }
            else {
                Dialog.alert({
                    title: "温馨提示",
                    message: "非商家用户，如有需要请联系上级开通",
                }).then(() => {
                    toPage("/");
                });
            }
        }
        else {
            return state.merInfo;
        }
    }
};
export default merGetters;
