var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?504f1e51b8b4408db7cbdf6a5505a617";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "vuex";
import { cache } from "./common/utils";
import router from "./router";
export default defineComponent({
  setup() {
    const store = useStore();
    if (cache("is_login")) cache("is_login", null);
    let path = window.location.pathname;
    if (path !== '/school') {
      //判断有无登录缓存 执行静默登录 拿到token
      if (cache("token")) {
        //拿到token后进行以下操作
        store.state.token = cache("token");
        store.getters.userinfo.then(e => {
          //一些初始化操作
          if (e.status == 0) {
            //用户处于拉黑状态
            router.replace({
              path: "/block"
            });
          }
          store.state.loginStatus = true;
        });
      } else {
        store.commit("login");
      }
    }
    const getFirstLevelRoute = route => {
      if (!Array.isArray(route.matched) || route.matched.length === 0) {
        return route;
      }
      return route.matched[0];
    };
    return {
      getFirstLevelRoute
    };
  }
});