import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({
      Component,
      route
    }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.getFirstLevelRoute(route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: _ctx.getFirstLevelRoute(route).name
    })) : _createCommentVNode("", true)], 1024)), !_ctx.getFirstLevelRoute(route).meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: _ctx.getFirstLevelRoute(route).name
    })) : _createCommentVNode("", true)]),
    _: 1
  });
}