const routers = [
    {
        path: "/mer/index",
        name: "merIndex",
        meta: {
            title: "商家首页",
            suffix: false,
        },
        component: () => import("../views/merchant/index.vue"),
    },
    {
        path: "/mer/check",
        name: "checkOrder",
        meta: {
            title: "核销订单",
            suffix: false,
        },
        component: () => import("../views/merchant/checkOrder.vue"),
    },
    {
        path: "/mer/good",
        name: "merGoodDetail",
        meta: {
            title: "产品详情",
            suffix: false,
        },
        component: () => import("../views/merchant/goodDetail.vue"),
    },
    {
        path: "/mer/order",
        name: "merOrder",
        meta: {
            title: "订单列表",
            suffix: false,
        },
        component: () => import("../views/merchant/order.vue"),
    },
    {
        path: "/mer/remark",
        name: "merRemark",
        meta: {
            title: "评论列表",
            suffix: false,
        },
        component: () => import("../views/merchant/remark.vue"),
    },
];
export default routers;
