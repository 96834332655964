const routers = [
    {
        path: "/admin/lock",
        name: "adminLock",
        component: () => import("../views/admin/lock.vue"),
    },
    {
        path: "/admin/post",
        name: "adminPost",
        component: () => import("../views/admin/post.vue"),
    },
    {
        path: "/admin/verify",
        name: "adminVerfy",
        component: () => import("../views/admin/verify.vue"),
    },
    {
        path: "/admin/discuss",
        name: "adminDiscuss",
        component: () => import("../views/admin/discuss.vue"),
    },
    {
        path: "/admin/analyze",
        name: "adminAnalyze",
        component: () => import("../views/admin/analyze.vue"),
    },
    {
        path: "/admin/earnings",
        name: "adminEarnings",
        component: () => import("../views/admin/earnings.vue")
    }, {
        path: "/admin/send_suf",
        name: "adminSendSuf",
        component: () => import("../views/admin/send_suf.vue")
    }, {
        path: "/admin/post_pop",
        name: "adminPostPop",
        component: () => import("../views/admin/post_pop.vue")
    }
];
export default routers;
